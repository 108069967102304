import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Dropdown from "./dropdown";
import Profile from "./profile";
import "./header.css";
import { useAuth0 } from "@auth0/auth0-react";
const sUrl = process.env.REACT_APP_SLOGO_URL;
const dUrl = process.env.REACT_APP_DILOGO_URL;
const bUrl = process.env.REACT_APP_BSLOGO_URL;
const defaultUrl = process.env.REACT_APP_CLOGO_URL;

function Header() {
  // eslint-disable-next-line
  const [logoUrl, setLogoUrl] = useState(null);
  const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
  const [userRole, setUserRole] = useState(null);

  // Grabs Role When it is availible
  useEffect(() => {
    if (isAuthenticated && user) {
      const fetchedRole = user["https://my-app.example.com/roles"][0];
      setUserRole(fetchedRole);
    }
  }, [user, isAuthenticated]);

  useEffect(() => {
    const fetchLogo = async (url) => {
      const token = await getAccessTokenSilently();
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const blob = await response.blob();
      const objectUrl = URL.createObjectURL(blob);
      setLogoUrl(objectUrl);
    };

    switch (userRole) {
      case "SmartATM Org":
        fetchLogo(sUrl);
        break;
      case "BlueSage Org":
        fetchLogo(bUrl);
        break;
      case "Dannah Org":
        fetchLogo(dUrl);
        break;
      default:
        setLogoUrl(defaultUrl);
    }
  }, [userRole, getAccessTokenSilently]);

  return (
    <header>
      <nav>
        <ul className="header">
          <li>{/* <img src={logoUrl} alt="Logo" className="logo" /> */}</li>
          <li>
            <Dropdown />
          </li>
          <li className="spacer"></li>
          <div className="flex">
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive ? "nav-link nav-link-active" : "nav-link"
                }
                to="/"
              >
                Home
              </NavLink>
            </li>
            {userRole === "Dannah Org" && (
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "nav-link nav-link-active" : "nav-link"
                  }
                  to="/portfolio"
                >
                  Portfolio
                </NavLink>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "nav-link nav-link-active" : "nav-link"
                  }
                  to="/Reports"
                >
                  Reports
                </NavLink>
              </li>
            )}
            {userRole === "BlueSage Org" && (
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "nav-link nav-link-active" : "nav-link"
                  }
                  to="/portfolio"
                >
                  Portfolio
                </NavLink>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "nav-link nav-link-active" : "nav-link"
                  }
                  to="/Reports"
                >
                  Reports
                </NavLink>
              </li>
            )}
            {userRole === "SmartATM Org" && (
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "nav-link nav-link-active" : "nav-link"
                  }
                  to="/transactions"
                >
                  Transactions
                </NavLink>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "nav-link nav-link-active" : "nav-link"
                  }
                  to="/Reports"
                >
                  Reports
                </NavLink>
              </li>
            )}
            <li>
              <Profile />
            </li>
          </div>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
