import React from 'react';
import { jsPDF } from "jspdf";
import logo from '../imgs/tclogo.png';
import './rebatereport.css';

function PartnerReport({ month, dataForMonth }) {
  const downloadReport = () => {
        // Create a new jsPDF instance
        const doc = new jsPDF();

        // Add logo
        // Note: You need to have the logo available as a Base64 encoded string
        doc.addImage(logo, 'png', 40, 20, 116, 30);

        // Add table header
        doc.setFontSize(32);
        doc.text('Location', 15, 77);
        doc.text('Date', 82, 77);
        doc.text('Sales Volume', 125, 77);
        doc.line(10, 80, 199, 80, 'S'); //Add Line(x1,y1,x2,y2, 'style')Graphing!

        // Add table body
        let totalSalesVolume = 0;
        dataForMonth.forEach((sale, index) => {
        const y = 90 + index * 10;
        doc.setFontSize(18);
        doc.text(sale.merchant_name, 10, y);
        doc.text(sale.month_end_date, 80, y);
        doc.text(`$`, 152, y);
        doc.text(`${new Intl.NumberFormat().format(sale.net_sales_vol)}`, 195, y, 'right');
        totalSalesVolume += parseFloat(sale.net_sales_vol);
        });

        // %'s are hardcoded for Specific Merchant in Server.js Reports Query, change as needed.
        let rebate = ((totalSalesVolume * .005) * .25);
        let QuotedRate = (totalSalesVolume * .03).toFixed(2);
        let PartnerSplit = (totalSalesVolume * .005).toFixed(2);
        let RefPartnerSplit = (PartnerSplit - rebate) * .092;
        let DannahSplit = ((PartnerSplit - RefPartnerSplit - rebate) / 2).toFixed(2);
        let Surchage = (totalSalesVolume * .035).toFixed(2);
        // Add totals
        doc.setFontSize(20);
        doc.text(`Total Sales Volume: `, 80, 123);
        doc.text(`$`, 152, 123);
        doc.text(`${new Intl.NumberFormat().format(totalSalesVolume)}`, 195, 123, 'right');
        doc.line(75, 125, 199, 125, 'S'); //Add Line(x1,y1,x2,y2, 'style')Graphing!
        doc.setFontSize(18);
        doc.text(`Surcharge:`, 15, 147);
        doc.text(`3.50%`, 80, 147);
        doc.text(`$`, 152, 147);
        doc.text(`${new Intl.NumberFormat().format(Surchage)}`, 195, 147, 'right');
        doc.line(10, 149, 199, 149, 'S'); //Add Line(x1,y1,x2,y2, 'style')Graphing!
        doc.text(`Quoted Rate:`, 15, 160);
        doc.text(`3.00%`, 80, 160);
        doc.text(`$`, 152, 160);
        doc.text(`${new Intl.NumberFormat().format(QuotedRate)}`, 195, 160, 'right');
        doc.line(10, 162, 199, 162, 'S'); //Add Line(x1,y1,x2,y2, 'style')Graphing!
        doc.text(`Partner Split:`, 15, 173);
        doc.text(`0.50%`, 80, 173);
        doc.text(`$`, 152, 173);
        doc.text(`${PartnerSplit}`, 195, 173, 'right');
        doc.line(10, 175, 199, 175, 'S'); //Add Line(x1,y1,x2,y2, 'style')Graphing!
        
        doc.text(`Rebate:`, 80, 193);
        doc.text(`$`, 152, 193);
        doc.text(`${rebate.toFixed(2)}`, 195, 193, 'right');
        doc.line(75, 195, 199, 195, 'S'); //Add Line(x1,y1,x2,y2, 'style')Graphing!
        doc.text(`Referral Partner Split:`, 80, 206);
        doc.text(`$`, 152, 206);
        doc.text(`${RefPartnerSplit.toFixed(2)}`, 195, 206, 'right');
        doc.line(75, 208, 199, 208, 'S'); //Add Line(x1,y1,x2,y2, 'style')Graphing!
        doc.text(`Dannah Split:`, 80, 221);
        doc.text(`$`, 152, 221);
        doc.text(`${DannahSplit}`, 195, 221, 'right');
        doc.line(75, 223, 199, 223, 'S'); //Add Line(x1,y1,x2,y2, 'style')Graphing!


        doc.setFontSize(12);
        doc.text(`* Rebate is 25% of Partner Split`, 15, 270);
        doc.text(`* Referral Partner Split is 9.2% of Partner Split After Rebate`, 15, 277);
        doc.text(`* Dannah Split is 50% After Rebate & Referral Partner Split`, 15, 284);

        // Save the PDF
        doc.save(`Partner_report_${month}.pdf`);
  }

  return (
    <button className='report-button' onClick={downloadReport}>
      Download {month}  Partner Report
    </button>
  );
}

export default PartnerReport;