import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import SmartTable from "../components/Transactions/smarttable";

function TransactionsPage() {
  const { isAuthenticated } = useAuth0();
  //role = user["https://my-app.example.com/roles"][0]; Could check role twice, once on front end and once on backend. !!Add user to useAuth0() imports

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div>
      <SmartTable />
    </div>
  );
}
export default TransactionsPage;
