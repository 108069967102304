import React from "react";

function SalesTable() {
  // const [sales, setSales] = useState(2161.0);
  // const [cashBack, setCashBack] = useState(2161.0);
  // const [tip, setTip] = useState(2161.0);

  // const total = sales + cashBack + tip;
  const sales = 0;
  const cashBack = 0;
  const tip = 0;
  const total = sales + cashBack + tip;

  return (
    <div className="sales">
      <table className="sales-table">
        <tbody>
          <tr>
            <th>Sales</th>
            <td>${sales.toFixed(2)}</td>
          </tr>
          <tr>
            <th>Cash Back</th>
            <td>${cashBack.toFixed(2)}</td>
          </tr>
          <tr>
            <th>Tip</th>
            <td>${tip.toFixed(2)}</td>
          </tr>
          <tr>
            <th>Total</th>
            <td>${total.toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default SalesTable;
