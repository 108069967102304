import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from "./loginbutton";
import LogoutButton from "./logoutbutton";

function Profile() {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) return <div>Loading...</div>;

  if (isAuthenticated) {
    const roles = user["https://my-app.example.com/roles"][0];
    // console.log(user);
    //Provides the user's role as a string, {JSON.stringify(user, null, 2)} to see entire user json
    // console.log(user['https://my-app.example.com/roles']);
    //const hasAdminRole = user["https://my-app.example.com/roles"].includes("BlueSage Admin");  Can use [0] to find first role
    //Can be used as a unique userid, but this only returns a "sub"
    return (
      <>
        <div className="nav-link profile-border">{roles}</div>
        <LogoutButton isInProfile={true} />
      </>
    );
  }

  return <LoginButton isInProfile={true} />;
}

export default Profile;
