import React from "react";
import DashboardData from "./DashboardData";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Heading from "../Heading";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function DashboardItem({
  image,
  tableName,
  daily,
  monthly,
  yearly,
  change,
  chartData, //represents the change in data, according to design.
  labelData,
}) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Remove x-axis grid lines
        },
      },
      y: {
        grid: {
          display: false, // Remove y-axis grid lines
        },
      },
    },
  };

  let labels = labelData;
  const dataState = {
    labels,
    datasets: [
      {
        data: chartData,
        backgroundColor: "rgba(0, 88, 255, 0.35)",
        barPercentage: 1.0, // Remove space between bars
        categoryPercentage: 1.0, // Remove space between bars
      },
    ],
  };
  return (
    <div className="dashboard-item">
      <Heading title={tableName} image={image} />
      <div className="dashboard-item-row row mobile-row">
        <DashboardData dataTitle="Daily" information={daily} change={change} />
        <DashboardData dataTitle="MTD" information={monthly} change={change} />
        <DashboardData dataTitle="YTD" information={yearly} change={change} />

        <div className="col-lg-3 dashboard-item-years">
          <Bar options={options} data={dataState} />
        </div>
      </div>
    </div>
  );
}

export default DashboardItem;
