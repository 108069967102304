import React from "react";

function TransactionSnapShot({ dataTitle, iconSrc, value }) {
  let amount = "";
  let decimal = "";

  if (typeof value === "string" && value.includes(".")) {
    const parts = value.split(".");
    amount = parts[0];
    decimal = parts[1];
  } else if (typeof value === "string") {
    amount = value;
  } else if (typeof value === "number") {
    amount = value.toString(); // Convert a number to a string
  }

  return (
    <div className="cols">
      <div className="dashboard-item-column tx-item-col">
        <div className="align-heading no-gap">
          <p className="day-heading normal">{dataTitle}</p>
          <div className="align-arrow">
            <div className="arrow-circle">
              <img className="arrow" src={iconSrc} alt="arrow-icon" />
            </div>
          </div>
        </div>
        <p className="number heavy">
          <span>{amount}</span> {/* Display the amount */}
          {decimal && <span className="decimal">{`.${decimal}`}</span>}{" "}
        </p>
      </div>
    </div>
  );
}

export default TransactionSnapShot;
