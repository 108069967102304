import React, { useEffect, useState, useContext } from "react";
import "./groupdaytable.css";
import { useAuth0 } from "@auth0/auth0-react";
import { DateTime } from "luxon";
import Loading from "./loading";
import { SelectedOptionContext } from "../components/selectedoptioncontext";
import ReportsChart from "./Reports/ReportsChart";
import ReportsNumbers from "./Reports/ReportsNumber";
import TerminalBreakdown from "./Reports/TerminalBreakdown";
import no_data from "../imgs/no_data.svg";

function GroupDayTable() {
  const [data, setData] = useState(null);
  const [date, setDate] = useState(new Date());
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const [fundingData, setFundingData] = useState(null);
  const [chartData, setChartData] = useState(null);
  const { selectedOption } = useContext(SelectedOptionContext);
  const groupdayURL = process.env.REACT_APP_BACKEND_GDAY_URL;
  const fundingURL = process.env.REACT_APP_BACKEND_FUNDING_URL;
  const hourlyReportURL = process.env.REACT_APP_BACKEND_HRCHART_URL;
  const [selectedValue, setSelectedValue] = useState(""); // Define the initial value
  const handleDropdownChange = (e) => {
    setSelectedValue(e.target.value);
  };
  const formattedDate = `${date.getFullYear()}-${String(
    date.getMonth() + 1
  ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;

  const handleChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    setItemsPerPage(newValue);
  };

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Set your initial items per page here
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(
          `${groupdayURL}?selectedOption=${selectedOption}&startDate=${formattedDate}&timeZone=${timeZone}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const responseData = await response.json();
        if (responseData.length > 0) {
          setData(responseData);
        } else {
          setData(null);
        }

        const fundingResponse = await fetch(
          `${fundingURL}?selectedOption=${selectedOption}&startDate=${formattedDate}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const fundingResponseData = await fundingResponse.json();

        if (fundingResponseData.length > 0) {
          setFundingData(fundingResponseData);
        } else {
          setFundingData(null);
        }

        const chartResponse = await fetch(
          `${hourlyReportURL}?startDate=${formattedDate}&selectedOption=${selectedOption}&timeZone=${timeZone}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const chartResponseData = await chartResponse.json();

        if (chartResponseData.length > 0) {
          setChartData(chartResponseData);
        } else {
          setChartData(null);
        }

        setIsLoading(false);
      } catch (err) {
        console.error(err);
        setIsLoading(false);
      }
    };
    if (selectedOption && selectedOption !== "Omni Channel") {
      fetchData();
    }
  }, [
    getAccessTokenSilently,
    groupdayURL,
    selectedOption,
    date,
    fundingURL,
    formattedDate,
    hourlyReportURL,
    timeZone,
  ]);

  if (isLoading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  // Pagination logic for fundingData
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPage = Math.ceil((fundingData?.length || 0) / itemsPerPage);
  const pageRange = `${startIndex + 1} to ${Math.min(
    endIndex,
    fundingData?.length || 0
  )} of ${fundingData?.length || 0}`;

  return (
    <>
      <div className="date-container">
        <div className="label-box">
          <label className="input-label">
            Date
            <input
              className="month"
              type="date"
              value={date.toISOString().substring(0, 10)}
              onChange={(event) => {    
                // Parse the date string and adjust it to the local timezone
                const selectedDate = new Date(event.target.value + 'T00:00:00');
                setDate(selectedDate);}}
            />
          </label>
        </div>
      </div>
      <div className="gap-container" style={{ marginBottom: "40px" }}>
        {data && (
          <>
            <ReportsChart chartData={chartData} />
            <ReportsNumbers data={data} />
          </>
        )}
        <TerminalBreakdown
          data={data}
          selectedValue={selectedValue}
          handleDropdownChange={handleDropdownChange}
          currentPage={currentPage}
          totalPage={totalPage}
          itemsPerPage={itemsPerPage}
          handleChange={handleChange}
          setCurrentPage={setCurrentPage}
          pageRange={pageRange}
        />
      </div>
      <>
        <div className="table-responsive">
          <h2 className="funding-table-h1 heavy">Funding Snapshot</h2>
          <table className="terminal-table table">
            <thead>
              <tr>
                <th>Location</th>
                <th>Period Start</th>
                <th>Period End</th>
                <th>Total Approved Withdrawals</th>
                <th>Total Fee Amount</th>
                <th>Total Funding Eligible</th>
              </tr>
            </thead>
            {fundingData ? (
              <tbody>
                {fundingData?.map((row, index) => {
                  // On hosted server this shows the correct 1400 timezone
                  const dtStart = DateTime.fromISO(row.start_time, {
                    zone: "America/Chicago",
                    setZone: false,
                  });
                  const dtEnd = DateTime.fromISO(row.end_time, {
                    zone: "America/Chicago",
                    setZone: false,
                  });

                  return (
                    <tr key={index}>
                      <td>{row.location_name}</td>
                      <td>{dtStart.toFormat("yyyy-MM-dd HH:mm:ss")}</td>
                      <td>{dtEnd.toFormat("yyyy-MM-dd HH:mm:ss")}</td>
                      <td>{row.total_successful_withdrawals}</td>
                      <td>{`$${row.total_surcharge_amount.toFixed(2)}`}</td>
                      <td>{`$${row.total_withdraw_amount.toFixed(2)}`}</td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <></>
            )}
          </table>
        </div>
        {fundingData ? (
          <></>
        ) : (
          <div className="no-data">
            <p className="no-data-text">No data found</p>
            <img className="table-illustration" src={no_data} alt="no-data" />
          </div>
        )}
      </>
    </>
  );
}

export default GroupDayTable;
