import React, { useEffect, useContext, useState } from "react";
import "./dropdown.css";
//useAuth0 is a hook, '@auth0/.... is a library
import { useAuth0 } from "@auth0/auth0-react";
import { SelectedOptionContext } from "./selectedoptioncontext";
const permissionUrl = process.env.REACT_APP_BACKEND_PERMISSION_URL;

function Dropdown() {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [permissions, setPermissions] = useState([]);
  const { selectedOption, setSelectedOption } = useContext(
    SelectedOptionContext
  );

  // Initialize options outside of conditionals
  let options = [];

  useEffect(() => {
    if (isAuthenticated) {
      // Assuming `user.sub` is the unique identifier for the user on Auth0
      const fetchPermissions = async () => {
        const token = await getAccessTokenSilently();

        try {
          const response = await fetch(permissionUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            // body: JSON.stringify({
            //     userId: id,
            // }),
          });

          if (!response.ok) {
            throw new Error("Failed to fetch permissions");
          }

          const data = await response.json();

          // Now you have the permissions
          setPermissions(data.permissions);
        } catch (error) {
          console.error("Error fetching permissions:", error);
        }
      };

      fetchPermissions();
    }
  }, [isAuthenticated, user, getAccessTokenSilently]);

  if (isAuthenticated) {
    // Maps Permissions Array Names only from Post Response
    options = permissions.map((permission) => permission.permission_name);
  }

  useEffect(() => {
    console.log(permissions?.length);
    console.log(selectedOption);
    if (!selectedOption || selectedOption === "Omni Channel") {
      // Check if selectedOption is not set
      if (permissions?.length > 0) {
        setSelectedOption(permissions[0].permission_name);
      } else {
        setSelectedOption("Omni Channel");
      }
    }
  }, [permissions, setSelectedOption, selectedOption]);

  // Call the onOptionChange callback whenever the selected option changes
  useEffect(() => {
    console.log(`Selected option: ${selectedOption}`);
  }, [selectedOption]);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  // If not authenticated, return null or some fallback UI
  if (!isAuthenticated) {
    return null;
  }

  return (
    <div className="dropdown-container ">
      <select value={selectedOption} onChange={handleChange}>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}

export default Dropdown;
