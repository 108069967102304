import React from "react";
import TransactionSnapShot from "../Transactions/TransactionSnapShot";
import Heading from "../Heading";
import image from "../../imgs/dollar.svg";
import SalesTable from "../SalesTable";
import paid from "../../imgs/Paid.svg";
import fee from "../../imgs/Fee.svg";
import decline from "../../imgs/Declined.svg";
import declineSales from "../../imgs/DeclinedSales.svg";

function ReportsNumbers({ data }) {
  let totalWithdrawals = 0;
  let totalFees = 0;
  let totalApproved = 0;
  let totalDeclined = 0;

  // Loop through the data to calculate the sums
  data.forEach((groupData) => {
    totalWithdrawals += groupData.total_withdrawals;
    totalFees += groupData.total_fees;
    totalApproved += parseInt(groupData.total_approved, 10); // Parse as integer
    totalDeclined += parseInt(groupData.total_declined, 10); // Parse as integer
  });

  return (
    <div className="dashboard-container">
      <div className="dashboard-item">
        <div className="space-between">
          <Heading title="Statistical Summary" image={image} />
        </div>
        <div className="dashboard-item-row row mobile-row">
          <TransactionSnapShot
            dataTitle="Withdrawn"
            iconSrc={paid}
            value={`$${new Intl.NumberFormat().format(totalWithdrawals)}`}
          />
          <TransactionSnapShot
            dataTitle="Fees"
            iconSrc={fee}
            value={`${new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(totalFees)}`}
          />
          <TransactionSnapShot
            dataTitle="Approved"
            iconSrc={decline}
            value={`${new Intl.NumberFormat().format(totalApproved)}`}
          />
          <TransactionSnapShot
            dataTitle="Declined"
            iconSrc={declineSales}
            value={`${new Intl.NumberFormat().format(totalDeclined)}`}
          />
          <SalesTable />
        </div>
      </div>
    </div>
  );
}

export default ReportsNumbers;
