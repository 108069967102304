import React from "react";
import right from "../../imgs/right_icon.svg";
import left from "../../imgs/left_icon.svg";

function TableActions({
  selectedValue,
  handleDropdownChange,
  currentPage,
  totalPage,
  itemsPerPage,
  handleChange,
  handlePrevPage,
  handleNextPage,
  pageRange, // Add pageRange prop
}) {
  return (
    <div className="table-dropdown-bottom">
      <div>
        <div className="page-item normal">
          <img
            className={`pagination-icon ${
              currentPage === 1 ? "opacity-03" : "opacity-01"
            }`}
            src={left}
            alt="left_icon"
            onClick={handlePrevPage}
            style={{ cursor: currentPage === 1 ? "not-allowed" : "pointer" }}
          />
          <span className="span-area">{pageRange}</span>
          <img
            className={`pagination-icon ${
              currentPage === totalPage ? "opacity-03" : "opacity-01"
            }`}
            src={right}
            alt="right_icon"
            onClick={handleNextPage}
            style={{
              cursor: currentPage === totalPage ? "not-allowed" : "pointer",
            }}
          />
        </div>
      </div>
      <div className="item-container">
        <label htmlFor="itemsPerPage" className="lable-item">
          Items per page:
        </label>
        <select
          className="itemsPerPage"
          id="itemsPerPage"
          value={itemsPerPage}
          onChange={handleChange}
        >
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>
    </div>
  );
}

export default TableActions;
