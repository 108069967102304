import React from "react";
// import numbersIcon from "../imgs/mdi_currency-usd.svg";
import breakdownIcon from "../../imgs/tabler_clock-hours-7.svg";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Heading from "../Heading";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function ReportsChart({ chartData }) {
  const [currentMetric, setCurrentMetric] = React.useState("total_withdrawals");

  if (!chartData) {
    return null;
  }

  const getCurrentData = () => {
    if (!chartData) return [];
    return chartData.map((item) => parseFloat(item[currentMetric]));
  };

  const currentData = getCurrentData();
  const dataMax = Math.max(...currentData);
  const dataMin = Math.min(...currentData);
  const buffer = 0.1 * dataMax; // Add a 10% buffer to the max value.

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#B6B6B6",
          font: {
            size: 12,
            family: "Satoshi",
            weight: "regular",
          },
        },
        grid: {
          display: false, // Remove x-axis grid lines
        },
      },
      y: {
        min: dataMin, // Set the min value.
        max: Math.round(dataMax + buffer), // Set the max value with a buffer.
        ticks: {
          color: "#B6B6B6",
          font: {
            size: 12,
            family: "Satoshi",
            weight: "regular",
          },
        },
        grid: {
          display: false, // Remove y-axis grid lines
        },
      },
    },
  };

  const dataState = {
    labels: chartData ? chartData.map((item) => item.tx_hour.toString()) : [],
    datasets: [
      {
        data: getCurrentData(),
        backgroundColor: "rgba(0, 88, 255, 0.35)",
        barPercentage: 1.0,
        categoryPercentage: 1.0,
      },
    ],
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard-item">
        <Heading title="Breakdown" image={breakdownIcon} />
        <div className="tab-container">
          <button
            className={`tab-button ${
              currentMetric === "total_withdrawals" ? "active" : ""
            }`}
            onClick={() => setCurrentMetric("total_withdrawals")}
          >
            Total Withdrawals
          </button>
          <button
            className={`tab-button ${
              currentMetric === "total_approved" ? "active" : ""
            }`}
            onClick={() => setCurrentMetric("total_approved")}
          >
            Total Approved
          </button>
          <button
            className={`tab-button ${
              currentMetric === "total_fees" ? "active" : ""
            }`}
            onClick={() => setCurrentMetric("total_fees")}
          >
            Total Fees
          </button>
          <button
            className={`tab-button ${
              currentMetric === "total_declined" ? "active" : ""
            }`}
            onClick={() => setCurrentMetric("total_declined")}
          >
            Total Declined
          </button>
        </div>

        <div className="dashboard-item-row">
          <Bar options={options} data={dataState} />
        </div>
      </div>
    </div>
  );
}

export default ReportsChart;
