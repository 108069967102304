import "./App.css";
import PortfolioPage from "./pages/portfoliopage";
import ReportsPage from "./pages/reportspage";
import HomePage from "./pages/homepage";
import Header from "./components/header";
import TransactionsPage from "./pages/transactionspage";
import { SelectedOptionProvider } from "./components/selectedoptioncontext";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Testing from "./pages/testing";
import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

function App() {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);
  
  return (
    <SelectedOptionProvider>
      <Router>
        <div>
          <Header />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/portfolio" element={<PortfolioPage />} />
            <Route path="/reports" element={<ReportsPage />} />
            <Route path="/transactions" element={<TransactionsPage />} />
            <Route path="/testing" element={<Testing />} />
          </Routes>
        </div>
      </Router>
    </SelectedOptionProvider>
  );
}

export default App;
