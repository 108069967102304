import React, { useEffect, useRef } from 'react';
import { Chart, CategoryScale, LinearScale, PointElement, Title, Legend, Tooltip, BarController, BarElement } from 'chart.js';

Chart.register(CategoryScale, LinearScale, PointElement, Title, Legend, Tooltip, BarController, BarElement);

function BarGraph({ data, labels, title }) {
    const chartRef = useRef(null);
    const chartInstance = useRef(null);
  
    useEffect(() => {
        if (chartRef.current) {
            // If there's already a chart instance, destroy it before creating a new one
            if (chartInstance.current) {
              chartInstance.current.destroy();
              chartInstance.current = null;
            }
      
            const ctx = chartRef.current.getContext('2d');
    
            chartInstance.current = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: data
            },
            options: {
                responsive: true,
                plugins: {
                legend: {
                    position: 'top',
                },
                title: {
                    display: true,
                    text: title,
                    font: {
                    size: 20
                    }
                }
                },
                scales: {
                x: {
                    ticks: {
                    font: {
                        size: 20
                    }
                    }
                },
                y: {
                    ticks: {
                    font: {
                        size: 20
                    }
                    }
                }
                }
            }
            });
        }
    }, [data, labels, title]);
  
    return <canvas ref={chartRef}></canvas>;
  }

export default BarGraph;