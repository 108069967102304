import React from "react";
import "./txsnapshot.css";
import dollar from "../imgs/dollar.svg";
import fee from "../imgs/Fees.svg";
import transaction from "../imgs/transaction 1.svg";
import tax from "../imgs/tabler_receipt-tax.svg";
import balance from "../imgs/iconoir_coins.svg";
import TransactionSnapShot from "./Transactions/TransactionSnapShot";
function TxSnapshot({ data }) {
  const totalAmount = data.reduce(
    (sum, row) => (row.rc === 0 ? sum + Number(row.withdrawal_amount) : sum),
    0
  );
  const totalFees = data.reduce(
    (sum, row) => (row.rc === 0 ? sum + Number(row.surcharged_amount) : sum),
    0
  );
  const totalTransactions = data.length;
  const approvedTransactions = data.filter((row) => row.rc === 0).length;
  const totalBalInq = data.filter(
    (row) => row.activity === 0 && row.rc === 0
  ).length;
  const percentApproved = (approvedTransactions / totalTransactions) * 100;

  return (
    <div>
      <div className="dashboard-container mt-40">
        <div className="dashboard-item">
          <div className="atm-align">
            <h2 className="heavy table-name">Overview</h2>
          </div>
          <div className="dashboard-item-row row mobile-row">
            <TransactionSnapShot
              dataTitle="Total Amount"
              iconSrc={dollar}
              value={`$${
                data?.length === 0
                  ? "-"
                  : new Intl.NumberFormat().format(totalAmount)
              }.`}
            />
            <TransactionSnapShot
              dataTitle="Total Fees"
              iconSrc={fee}
              value={`${
                data?.length === 0
                  ? "-"
                  : new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }).format(totalFees)
              }.`}
            />
            <TransactionSnapShot
              dataTitle="Transactions Approved"
              iconSrc={tax}
              value={`${
                data?.length === 0 ? "-" : percentApproved.toFixed(2) + "%"
              }`}
            />
            <TransactionSnapShot
              dataTitle="Total Transactions"
              iconSrc={transaction}
              value={`${data?.length === 0 ? "-" : totalTransactions}`}
            />
            <TransactionSnapShot
              dataTitle="Total Balance Inquiries"
              iconSrc={balance}
              value={`${data?.length === 0 ? "-" : totalBalInq}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TxSnapshot;
