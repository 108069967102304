import LineChart from '../components/linechart';
import BarGraph from '../components/bargraph';
import EagleTable from '../components/eagletable';
import React, { useEffect, useState } from 'react';
import './portfoliopage.css';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from '../components/loading';

function PortfolioPage() {

    const { getAccessTokenSilently } = useAuth0();
    const [sales, setSales] = useState([]);
    const [datasets1, setDatasets1] = useState([]);
    const [datasets2, setDatasets2] = useState([]);
    const [datasets3, setDatasets3] = useState([]);
    const [labels, setLabels] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const prodURL = process.env.REACT_APP_BACKEND_PROD_URL

    useEffect(() => {

    const fetchData = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(prodURL, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
        const data = await response.json();
        setSales(data);
        setIsLoading(false); // Ends loading JSX after error occurs so people don't wait forever.
      } catch (err) {
        console.error(err);
        setIsLoading(false); // Ends loading JSX after error occurs so people don't wait forever.
      }
    };
    fetchData();// Must keep comment on line 25, disables warning given when using env variable in useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    useEffect(() => {
      // Create the charts
      if (sales.length > 0) {
    
          // Group data by merchant
          const groupedData = sales.reduce((acc, sale) => {
            if (!acc[sale.merchant_name]) {
              acc[sale.merchant_name] = [];
            }
            acc[sale.merchant_name].push(sale);
            return acc;
          }, {});
    
          //Defines Colors for lines
          function getColor(merchant, shift = 0) {
            let sum = 0;
            for (let i = 0; i < merchant.length; i++) {
              sum += merchant.charCodeAt(i);
            }
            const hue = (sum + shift) % 360; // Use the sum to get a hue value (0-360)
            return `hsl(${hue}, 50%, 50%)`; // Return an HSL color
          }
          
          // Create a dataset for each table
          const newDatasets1 = Object.keys(groupedData).map((merchant, index) => {
              return {
              label: merchant,
              data: groupedData[merchant].map(item => item.net_sales_vol),
              fill: false,
              borderColor: getColor(merchant),
              tension: 0.1
              };
          });
          
          // Create a dataset for each table
          const newDatasets2 = Object.keys(groupedData).map((merchant, index) => {
              return {
              label: merchant,
              data: groupedData[merchant].map(item => item.iso_net_revenue_share),
              fill: false,
              borderColor: getColor(merchant),
              tension: 0.1
              };
          });

          //Data Structure for bar chart from Production Page
          const newDatasets3 = [];
          Object.keys(groupedData).forEach((merchant, index) => {
            // Dataset for total transactions
            newDatasets3.push({
              label: `${merchant} Total Transactions`,
              data: groupedData[merchant].map(item => ({
                x: item.month_end_date,
                y: item.total_transactions
              })),
              backgroundColor: getColor(merchant),
              stack: merchant, // Specifies which stack data appears on
              order: 2, //The drawing order of dataset(top/bottom). Also affects order for stacking, tooltip and legend.
            });
    
            // Dataset for total returns
            newDatasets3.push({
              label: `${merchant} Total Returns`,
              data: groupedData[merchant].map(item => ({
                x: item.month_end_date,
                y: item.total_returns
              })),
              backgroundColor: getColor(merchant, 180),
              stack: merchant, // Add this line
              order: 1, //The drawing order of dataset(top/bottom). Also affects order for stacking, tooltip and legend.
            });
          });

          const newLabels = [...new Set(sales.map(item => item.month_end_date))];

          setDatasets1(newDatasets1);
          setDatasets2(newDatasets2);
          setDatasets3(newDatasets3);
          setLabels(newLabels);

          }
      }, [sales]);

    if (isLoading) {
      return (
      <div>
        <Loading />
      </div>
      ); // Show a loading message while the data is being fetched
    }
       
  return (
  <div>
    <div className='chart-container-row2'>
        <LineChart data={datasets1} labels={labels} title="Net Sales Volume" />
    </div>
    <div className='chart-container-row2'>
      <LineChart data={datasets2} labels={labels} title="ISO Net Revenue" />
    </div>
    <div className='chart-container-row2'>
        <BarGraph data={datasets3} labels={labels} title="Total Transactions & Total Returns" />
    </div>
    <EagleTable/>
  </div>
)}

export default PortfolioPage;