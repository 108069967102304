import React from "react";
import breakdownIcon from "../imgs/tabler_clock-hour-7.svg";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function Testing() {
  const chartData = [
    1200, 2500, 1800, 900, 1500, 2200, 2800, 700, 1600, 3000, 1300, 800, 250,
    1700, 2000, 1100, 1900, 220, 2700, 1400, 600, 2400, 2600, 400, 2900, 1000,
    700, 1200, 2100, 300,
  ];
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Remove x-axis grid lines
        },
      },
      y: {
        suggestedMin: 0,
        suggestedMax: 3000,
        grid: {
          display: false, // Remove y-axis grid lines
        },
      },
    },
  };

  const labels = [
    "18 May",
    "19 May",
    "20 May",
    "21 May",
    "22 May",
    "23 May",
    "24 May",
    "25 May",
    "26 May",
    "27 May",
    "28 May",
    "29 May",
    "30 May",
    "31 May",
    "01 Jun",
    "02 Jun",
    "03 Jun",
    "04 Jun",
    "05 Jun",
    "06 Jun",
    "07 Jun",
    "08 Jun",
    "09 Jun",
    "10 Jun",
    "11 Jun",
    "12 Jun",
    "13 Jun",
    "14 Jun",
    "15 Jun",
    "16 Jun",
  ];
  const dataState = {
    labels,
    datasets: [
      {
        data: chartData,
        backgroundColor: "rgba(0, 88, 255, 0.35)",
        barPercentage: 1.0, // Remove space between bars
        categoryPercentage: 1.0, // Remove space between bars
      },
    ],
  };
  return (
    <div className="container" style={{ marginTop: "100px" }}>
      <div className="row">
        <div className="col-lg-12">
          <div className="gap-container">
            <div className="dashboard-container">
              <div className="dashboard-item">
                <div className="atm-align">
                  <div className="atm_circle">
                    <img
                      className="atm-logo"
                      src={breakdownIcon}
                      alt="atm_logo"
                    />
                  </div>
                  <h2 className="heavy table-name">Daily Breakdown</h2>
                </div>
                <div className="dashboard-item-row">
                  <div style={{ maxHeight: "200px", width: "100%" }}></div>
                  <Bar data={dataState} options={options} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testing;
