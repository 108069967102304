import React, { useEffect, useState } from 'react';
import './eagletable.css';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from './loading';


function EagleTable() {
    const { getAccessTokenSilently } = useAuth0();
    const [originalSales, setOriginalSales] = useState([]);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState(null);
    const [sales, setSales] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const prodURL = process.env.REACT_APP_BACKEND_PROD_URL
  

    useEffect(() => {
      const fetchData = async () => {
        try {
          const token = await getAccessTokenSilently();
          const response = await fetch(prodURL, {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          });
          const data = await response.json();
          setSales(data);
          setOriginalSales(data);
          setIsLoading(false); // Changes IsLoading State variable to signify the end of loading
        } catch (err) {
          // handle error here
          setIsLoading(false); // Ends loading JSX after error occurs so people don't wait forever.
        }
      };
      fetchData();// Must keep comment on line 25, disables warning given when using env variable in useEffect
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


  
    function handleColumnHeaderClick(column, type) {
        let sortedSales = [...sales];
        if (sortColumn === column) {
          if (sortDirection === 'desc') {
            // If already sorted in descending order, reset to original order
            sortedSales = [...originalSales];
            setSortDirection(null);
          } else {
            // Otherwise, sort the data
            sortedSales.sort((a, b) => {
              if (type === 'number') {
                return sortDirection === 'asc' ? a[column] - b[column] : b[column] - a[column];
              } else if (type === 'string') {
                return sortDirection === 'asc' ? a[column].localeCompare(b[column]) : b[column].localeCompare(a[column]);
              } else if (type === 'date') {
                const dateA = new Date('1-' + a[column]);
                const dateB = new Date('1-' + b[column]);
                return sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
              }
              return 0;
            });
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
          }
        } else {
          // If a different column is clicked, sort in ascending order
          sortedSales.sort((a, b) => {
            if (type === 'number') {
              return a[column] - b[column];
            } else if (type === 'string') {
              return a[column].localeCompare(b[column]);
            } else if (type === 'date') {
              const dateA = new Date('1-' + a[column]);
              const dateB = new Date('1-' + b[column]);
              return dateA - dateB;
            }
            return 0;
          });
          setSortColumn(column);
          setSortDirection('asc');
        }
        setSales(sortedSales);
      }

      if (isLoading) {
        return (
        <div>
          <Loading />
        </div>
        ); // Show a loading message while the data is being fetched
      }
  
    return (
      <div className='table-container'>
        <h2 style={{ textAlign: 'center' }}>Monthly Data</h2>
        <table>
            <thead>
            <tr>
                <th className='sortable' onClick={() => handleColumnHeaderClick('merchant_name', 'string')}>Merchant Name {sortColumn === 'merchant_name' ? (sortDirection === 'asc' ? '▲' : sortDirection === 'desc' ? '▼' : '↕') : ''}</th>
                <th className='sortable' onClick={() => handleColumnHeaderClick('month_end_date', 'date')}>Month End Date {sortColumn === 'month_end_date' ? (sortDirection === 'asc' ? '▲' : sortDirection === 'desc' ? '▼' : '↕') : ''}</th>
                <th className="black-header" onClick={() => handleColumnHeaderClick('net_sales_vol', 'number')}>Net Sales Volume {sortColumn === 'net_sales_vol' ? (sortDirection === 'asc' ? '▲' : sortDirection === 'desc' ? '▼' : '↕') : ''}</th>
                <th className='sortable' onClick={() => handleColumnHeaderClick('total_transactions', 'number')}>Total Transactions {sortColumn === 'total_transactions' ? (sortDirection === 'asc' ? '▲' : sortDirection === 'desc' ? '▼' : '↕') : ''}</th>
                <th className='sortable' onClick={() => handleColumnHeaderClick('iso_net_revenue_share', 'number')}>ISO Net Revenue Share {sortColumn === 'iso_net_revenue_share' ? (sortDirection === 'asc' ? '▲' : sortDirection === 'desc' ? '▼' : '↕') : ''}</th>
                <th className='sortable' onClick={() => handleColumnHeaderClick('total_income', 'number')}>Total Fee Income {sortColumn === 'total_income' ? (sortDirection === 'asc' ? '▲' : sortDirection === 'desc' ? '▼' : '↕') : ''}</th>
                <th className='sortable' onClick={() => handleColumnHeaderClick('net_income', 'number')}>Net Fee Income {sortColumn === 'net_income' ? (sortDirection === 'asc' ? '▲' : sortDirection === 'desc' ? '▼' : '↕') : ''}</th>
                <th className='sortable' onClick={() => handleColumnHeaderClick('total_returns', 'number')}>Total Returns {sortColumn === 'total_returns' ? (sortDirection === 'asc' ? '▲' : sortDirection === 'desc' ? '▼' : '↕') : ''}</th>
                <th className='sortable' onClick={() => handleColumnHeaderClick('total_return_amt', 'number')}>Total Return Amount {sortColumn === 'total_return_amt' ? (sortDirection === 'asc' ? '▲' : sortDirection === 'desc' ? '▼' : '↕') : ''}</th>
                <th className="black-header" onClick={() => handleColumnHeaderClick('avg_return_amount', 'number')}>Avg Return Amount {sortColumn === 'avg_return_amount' ? (sortDirection === 'asc' ? '▲' : sortDirection === 'desc' ? '▼' : '↕') : ''}</th>
                <th className="black-header" onClick={() => handleColumnHeaderClick('avg_transaction_amount', 'number')}>Avg Transaction Amount {sortColumn === 'avg_transaction_amount' ? (sortDirection === 'asc' ? '▲' : sortDirection === 'desc' ? '▼' : '↕') : ''}</th>
                <th className="black-header" onClick={() => handleColumnHeaderClick('effective_rate', 'number')}>Effective Rate {sortColumn === 'effective_rate' ? (sortDirection === 'asc' ? '▲' : sortDirection === 'desc' ? '▼' : '↕') : ''}</th>
                <th className="black-header" onClick={() => handleColumnHeaderClick('iso_rev_split', 'number')}>Iso Revenue Split % {sortColumn === 'iso_rev_split' ? (sortDirection === 'asc' ? '▲' : sortDirection === 'desc' ? '▼' : '↕') : ''}</th>
            </tr>
            </thead>
            <tbody>
            {sales.map((sale, index) => (
                <tr key={index}>
                <td>{sale.merchant_name}</td>
                <td>{sale.month_end_date}</td>
                <td>{`$${new Intl.NumberFormat().format(sale.net_sales_vol)}`}</td>
                <td>{sale.total_transactions}</td>
                <td>{`$${new Intl.NumberFormat().format(sale.iso_net_revenue_share)}`}</td>
                <td>{`$${new Intl.NumberFormat().format(sale.total_income)}`}</td>
                <td>{`$${new Intl.NumberFormat().format(sale.net_income)}`}</td>
                <td>{sale.total_returns}</td>
                <td>{`$${new Intl.NumberFormat().format(sale.total_return_amt)}`}</td>
                <td>{`$${new Intl.NumberFormat().format(sale.avg_return_amount)}`}</td>
                <td>{`$${new Intl.NumberFormat().format(sale.avg_transaction_amount)}`}</td>
                <td>{sale.effective_rate}</td>
                <td>{sale.iso_rev_split}</td>
                </tr>
            ))}
            </tbody>
        </table>
      </div>
    );
  }
  
  export default EagleTable;