import React, { useEffect, useState, useContext } from "react";
import "./groupdaytable.css";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "./loading";
import { SelectedOptionContext } from "../components/selectedoptioncontext";
import ReportsChart from "./Reports/ReportsChart";
import ReportsNumbers from "./Reports/ReportsNumber";
import TerminalBreakdown from "./Reports/TerminalBreakdown";
import no_data from "../imgs/no_data.svg";

function GroupMonthTable() {
  const [data, setData] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1); // Adding 1 because getMonth() returns 0-11
  const [date, setDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [preset, setPreset] = useState(
    `${year}-${month.toString().padStart(2, "0")}`
  );
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const { selectedOption } = useContext(SelectedOptionContext);
  const groupdayURL = process.env.REACT_APP_BACKEND_GDAY_URL;
  const monchartURL = process.env.REACT_APP_BACKEND_MONCHART_URL;
  const [selectedValue, setSelectedValue] = useState("");

  const handleDropdownChange = (e) => {
    setSelectedValue(e.target.value);
  };

  const handleChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    setItemsPerPage(newValue);
  };

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await getAccessTokenSilently();
        const formattedDate = `${year}-${month.toString().padStart(2, "0")}-01`;
        const lastDay = new Date(year, month, 0);
        const formattedEndDate = `${year}-${month
          .toString()
          .padStart(2, "0")}-${lastDay.getDate()}`;
        const response = await fetch(
          `${groupdayURL}?selectedOption=${selectedOption}&startDate=${formattedDate}&endDate=${formattedEndDate}&timeZone=${timeZone}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const responseData = await response.json();
        if (responseData.length > 0) {
          setData(responseData);
        } else {
          setData(null);
        }
        const chartResponse = await fetch(
          `${monchartURL}?startDate=${formattedDate}&selectedOption=${selectedOption}&month=${month}&year=${year}&timeZone=${timeZone}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const chartResponseData = await chartResponse.json();

        if (chartResponseData.length > 0) {
          setChartData(chartResponseData);
        } else {
          setChartData(null);
        }
        setIsLoading(false);
      } catch (err) {
        console.error(err);
        setIsLoading(false);
      }
    };
    if (selectedOption && selectedOption !== "Omni Channel") {
      fetchData();
    }
  }, [getAccessTokenSilently, groupdayURL, selectedOption, year, month, monchartURL, timeZone]);

  useEffect(() => {
    let start, end;
    switch (preset) {
      case `${year}-${month.toString().padStart(2, "0")}`:
        start = new Date(year, month - 1, 1); // Subtracting 1 from month to make it 0-11
        end = new Date(year, month, 0);
        break;
      case "Last Month":
        start = new Date(year, month - 2, 1); // Subtracting 2 from month to go back to the previous month
        end = new Date(year, month - 1, 0);
        break;
      case "Custom Range":
        return;
      default:
        break;
    }
    setDate(start);
    setEndDate(end);
  }, [preset, year, month]);

  if (isLoading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  // Pagination logic
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPage = Math.ceil((data?.length || 0) / itemsPerPage);
  const pageRange = `${startIndex + 1} to ${Math.min(
    endIndex,
    data?.length || 0
  )} of ${data?.length || 0}`;

  return (
    <>
      <div className="date-container">
        <div className="label-box">
          <label className="date-label">Select Month</label>
          <input
            id=""
            type="month"
            className="month date-inp"
            value={preset}
            onChange={(e) => {
              const selectedYear = parseInt(e.target.value.substring(0, 4), 10);
              const selectedMonth = parseInt(e.target.value.substring(5), 10);
              setYear(selectedYear);
              setMonth(selectedMonth);
              setPreset(e.target.value);
            }}
            min="2018-01"
          />
        </div>

        {preset === "Custom Range" && date && endDate && (
          <>
            <label className="input-label">
              Start Date:
              <input
                type="date"
                value={date.toISOString().substring(0, 10)}
                onChange={(event) => setDate(new Date(event.target.value))}
              />
            </label>
            <label className="input-label">
              End Date:
              <input
                type="date"
                value={endDate.toISOString().substring(0, 10)}
                onChange={(event) => setEndDate(new Date(event.target.value))}
              />
            </label>
          </>
        )}
      </div>
      {data && (
        <div className="gap-container" style={{ marginBottom: "40px" }}>
          {data && (
            <>
              <ReportsChart chartData={chartData} />
              <ReportsNumbers data={data} />
            </>
          )}
          <TerminalBreakdown
            data={data}
            selectedValue={selectedValue}
            handleDropdownChange={handleDropdownChange}
            currentPage={currentPage}
            totalPage={totalPage}
            itemsPerPage={itemsPerPage}
            handleChange={handleChange}
            setCurrentPage={setCurrentPage}
            pageRange={pageRange}
          />
        </div>
      )}
      {data ? (
        <></>
      ) : (
        <div className="no-data">
          <p className="no-data-text">No data found</p>
          <img className="table-illustration" src={no_data} alt="no-data" />
        </div>
      )}
    </>
  );
}

export default GroupMonthTable;
