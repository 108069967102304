import React, { useEffect, useState, useContext } from "react";
import "../smarttable.css";
import { useAuth0 } from "@auth0/auth0-react";
import TxSnapshot from "../txsnapshot";
import Loading from "../loading";
import { SelectedOptionContext } from "../selectedoptioncontext";
import TransactionFilters from "./TransactionFilter";
import TransactionTable from "./TransactionTable";
import TableActions from "./TableActions";
import DownloadCSV from "./CsvDownload";

function SmartTable() {
  const { getAccessTokenSilently } = useAuth0();
  const [originalData, setOriginalData] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);

  const [data, setData] = useState([]);
  const [locations, setLocations] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { selectedOption } = useContext(SelectedOptionContext);
  const smartURL = process.env.REACT_APP_BACKEND_SMART_URL;
  const locationsURL = process.env.REACT_APP_BACKEND_LOCATIONS_URL;
  const [selectedValue, setSelectedValue] = useState("all");
  const [miniLoader, setMiniLoader] = useState(true);

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const handleChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    setItemsPerPage(newValue);
    setCurrentPage(1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const totalPage = Math.ceil(data.length / itemsPerPage);

  const handleDropdownChange = (e) => {
    setSelectedValue(e.target.value);
  };

  const [serialNum, setSerialNum] = useState("");
  const [statusFilter, setStatusFilter] = useState(''); // This is read & stored in DB as 0 & 1 integer. Think it should be a dropdown option list of Approved or Denied
  const [panNumber, setPanNumber] = useState("");
  const [startDate, setStartDate] = useState(
    new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
  );
  const [endDate, setEndDate] = useState(
    new Date(Date.now() + 1 * 24 * 60 * 60 * 1000)
  );
  const [locationFilter, setLocationFilter] = useState("");

  const handleSerialNumChange = (event) => {
    setSerialNum(event.target.value);
  };

  const handleLocationChange = (location) => {
    setLocationFilter((prevState) => ({
      ...prevState,
      [location]: !prevState[location],
    }));
  };

  const handlePanNumberChange = (event) => {
    setPanNumber(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatusFilter(event.target.value);
  };

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const token = await getAccessTokenSilently();
        const url = locationsURL;
        const response = await fetch(
          `${url}?selectedOption=${selectedOption}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const locations = await response.json();
        setLocations(locations);
      } catch (err) {
        console.error(err);
      }
    };

    if (selectedOption && selectedOption !== "Omni Channel") {
      fetchData();
      fetchLocations();
    }
    // eslint-disable-next-line
  }, [getAccessTokenSilently, selectedOption, locationsURL]);

  const fetchData = async () => {
    try {
      const token = await getAccessTokenSilently();
      let params = new URLSearchParams();
      if (serialNum) {
        params.append("serialNum", serialNum);
      }
      if (startDate) {
        params.append("startDate", startDate.toISOString());
      }
      if (endDate) {
        params.append("endDate", endDate.toISOString());
      }
      if (locationFilter) {
        params.append("location", locationFilter);
      }
      if (panNumber) {
        params.append("panNumber", panNumber);
      }
      if (statusFilter) {
        // Assuming backend expects integers 0 or 1
        const statusValue = statusFilter === 'Approved' ? 0 : 1;
        params.append("statusFilter", statusValue);
      }

      const selectedLocations = Object.keys(locationFilter).filter(
        (location) => locationFilter[location]
      );
      const locationString = selectedLocations.join(",");

      params.append("locations", locationString);

      const fullURL = `${smartURL}?selectedOption=${selectedOption}&${params.toString()}`;
      const response = await fetch(fullURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setData(data);
      setOriginalData(data);
      setIsLoading(false);
      setMiniLoader(false);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
      setMiniLoader(false);
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    fetchData(serialNum, startDate, endDate, locationFilter, panNumber);
  };

  function handleColumnHeaderClick(column, type) {
    let sortedData = [...data];
    if (sortColumn === column) {
      if (sortDirection === "desc") {
        sortedData = [...originalData];
        setSortDirection(null);
      } else {
        sortedData.sort((a, b) => {
          if (type === "number") {
            return sortDirection === "asc"
              ? a[column] - b[column]
              : b[column] - a[column];
          } else if (type === "string") {
            return sortDirection === "asc"
              ? a[column].localeCompare(b[column])
              : b[column].localeCompare(a[column]);
          } else if (type === "date") {
            const dateA = new Date(a[column]);
            const dateB = new Date(b[column]);
            return sortDirection === "asc" ? dateA - dateB : dateB - dateA;
          }
          return 0;
        });
        setSortDirection(sortDirection === "asc" ? "desc" : "asc");
      }
    } else {
      sortedData.sort((a, b) => {
        if (type === "number") {
          return a[column] - b[column];
        } else if (type === "string") {
          return a[column].localeCompare(b[column]);
        } else if (type === "date") {
          const dateA = new Date(a[column]);
          const dateB = new Date(b[column]);
          return dateA - dateB;
        }
        return 0;
      });
      setSortColumn(column);
      setSortDirection("asc");
    }
    setData(sortedData);
  }

  if (isLoading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, data.length);
  const displayedData = data?.slice(startIndex, endIndex);

  const pageStart = startIndex + 1;
  const pageEnd = Math.min(startIndex + itemsPerPage, data.length);

  const pageRange = `${pageStart} to ${pageEnd} of ${data.length}`;

  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="table-container">
              <TxSnapshot data={data} />
              <DownloadCSV data = {data} />
              <TransactionFilters
                serialNum={serialNum}
                handleSerialNumChange={handleSerialNumChange}
                isDropdownOpen={isDropdownOpen}
                setIsDropdownOpen={() => setIsDropdownOpen(!isDropdownOpen)}
                locations={locations}
                locationFilter={locationFilter}
                handleLocationChange={handleLocationChange}
                panNumber={panNumber}
                handlePanNumberChange={handlePanNumberChange}
                statusFilter={statusFilter}
                handleStatusChange={handleStatusChange}
                startDate={startDate}
                handleStartDateChange={(event) =>
                  setStartDate(new Date(event.target.value))
                }
                endDate={endDate}
                handleEndDateChange={(event) =>
                  setEndDate(new Date(event.target.value))
                }
                handleFormSubmit={handleFormSubmit}
              />

              <TransactionTable
                data={displayedData}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
                handleColumnHeaderClick={handleColumnHeaderClick}
                setMiniLoader={miniLoader}
              />
              <TableActions
                selectedValue={selectedValue}
                handleDropdownChange={handleDropdownChange}
                currentPage={currentPage}
                totalPage={totalPage}
                itemsPerPage={itemsPerPage}
                handleChange={handleChange}
                handlePrevPage={handlePrevPage}
                handleNextPage={handleNextPage}
                pageRange={pageRange}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SmartTable;
