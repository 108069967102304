import React, { useEffect, useState, useContext } from "react";
import "./smartdashvalues.css";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "./loading";
import { SelectedOptionContext } from "../components/selectedoptioncontext";
import Withdrawals from "../imgs/atm.svg";
import Fee from "../imgs/Fees.svg";
import DashboardItem from "./Dashboard/DashboardItem"; // Import the DashboardItem component

function SmartDashValues() {
  const [data, setData] = useState(null);
  const [chartData, setChartData] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const { selectedOption } = useContext(SelectedOptionContext);
  const sdashURL = process.env.REACT_APP_BACKEND_SDASH_URL;
  const wklyURL = process.env.REACT_APP_BACKEND_WKLY_URL;
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(
          `${sdashURL}?selectedOption=${selectedOption}&timeZone=${timeZone}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const responseData = await response.json();
        setData(responseData[0]);
        const chartResponse = await fetch(
          `${wklyURL}?selectedOption=${selectedOption}&timeZone=${timeZone}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const chartResponseData = await chartResponse.json();

        if (chartResponseData.length > 0) {
          setChartData(chartResponseData);
        } else {
          setChartData(null);
        }
        setIsLoading(false);
      } catch (err) {
        console.error(err);
        setIsLoading(false);
      }
    };

    if (selectedOption && selectedOption !== "Omni Channel") {
      fetchData();
    }
  }, [getAccessTokenSilently, sdashURL, selectedOption, wklyURL, timeZone]);

  const startYear = 2020;
  const endYear = 2030;
  const years = [];
  for (let year = startYear; year <= endYear; year++) {
    years.push(year);
  }

  if (isLoading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  const chartDataWithdrawals = chartData.map(item => item.total_withdrawals);
  const chartDataFees = chartData.map(item => item.total_fees);
  const chartDataApproved = chartData.map(item => parseInt(item.total_approved, 10));
  const chartDataDeclined = chartData.map(item => parseInt(item.total_declined, 10));
  const chartDataLabel = chartData.map(item => item.tx_hour.substring(5));

  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="gap-container mt-40">
              <div className="dashboard-container">
                <DashboardItem
                  tableName="ATM Withdrawls"
                  image={Withdrawals}
                  change={true}
                  daily={`$${new Intl.NumberFormat().format(
                    data.total_withdrawals_day
                  )}`}
                  monthly={`$${new Intl.NumberFormat().format(
                    data.total_withdrawals_month
                  )}`}
                  yearly={`$${new Intl.NumberFormat().format(
                    data.total_withdrawals_year
                  )}`}
                  chartData={chartDataWithdrawals}
                  labelData ={chartDataLabel}
                />
              </div>
              <div className="dashboard-container">
                <DashboardItem
                  tableName="ATM Fees"
                  image={Fee}
                  change={true}
                  daily={`$${new Intl.NumberFormat().format(
                    data.total_fees_day
                  )}`}
                  monthly={`$${new Intl.NumberFormat().format(
                    data.total_fees_month
                  )}`}
                  yearly={`$${new Intl.NumberFormat().format(
                    data.total_fees_year
                  )}`}
                  chartData={chartDataFees}
                  labelData ={chartDataLabel}
                />
              </div>
              <div className="dashboard-container">
                <DashboardItem
                  tableName="Approved Transactions"
                  image={Fee}
                  change={true}
                  daily={`${new Intl.NumberFormat().format(
                    data.total_approved_day
                  )}`}
                  monthly={`${new Intl.NumberFormat().format(
                    data.total_approved_month
                  )}`}
                  yearly={`${new Intl.NumberFormat().format(
                    data.total_approved_year
                  )}`}
                  chartData={chartDataApproved}
                  labelData ={chartDataLabel}
                />
              </div>
              <div className="dashboard-container">
                <DashboardItem
                  tableName="Declined Transactions"
                  image={Fee}
                  change={true}
                  daily={`${new Intl.NumberFormat().format(
                    data.total_declined_day
                  )}`}
                  monthly={`${new Intl.NumberFormat().format(
                    data.total_declined_month
                  )}`}
                  yearly={`${new Intl.NumberFormat().format(
                    data.total_declined_year
                  )}`}
                  chartData={chartDataDeclined}
                  labelData ={chartDataLabel}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SmartDashValues;
