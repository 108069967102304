import React, { useEffect, useState, useContext } from "react";
import "./fundingtable.css";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "./loading";
import { DateTime } from "luxon";
import { SelectedOptionContext } from "../components/selectedoptioncontext";
import TableActions from "./Transactions/TableActions";
import no_data from "../imgs/no_data.svg";

function FundingTable() {
  const { getAccessTokenSilently } = useAuth0();
  const [originalData, setOriginalData] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { selectedOption } = useContext(SelectedOptionContext);
  const fundingURL = process.env.REACT_APP_BACKEND_FUNDING_URL;

  const [selectedValue, setSelectedValue] = useState("all");
  const [itemsPerPage, setItemsPerPage] = useState(25); // Initial value
  const [currentPage, setCurrentPage] = useState(1); // Initialize the current page

  const handleChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    setItemsPerPage(newValue);
    setCurrentPage(1); // Reset to the first page when itemsPerPage changes
  };

  const totalPage = Math.ceil(data.length / itemsPerPage);

  const handleDropdownChange = (e) => {
    setSelectedValue(e.target.value);
    setCurrentPage(1); // Reset to the first page when selected page changes
  };

  // Function to fetch data from the backend
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(
          `${fundingURL}?selectedOption=${selectedOption}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        setData(data);
        setOriginalData(data);
        setIsLoading(false);
      } catch (err) {
        console.error(err);
        setIsLoading(false);
      }
    };

    // Fetches data as long as Selected Option is present and it doesn't equal the default value.
    if (selectedOption && selectedOption !== "Omni Channel") {
      fetchData();
    }
  }, [getAccessTokenSilently, fundingURL, selectedOption]);

  function handleColumnHeaderClick(column, type) {
    let sortedData = [...data];
    if (sortColumn === column) {
      if (sortDirection === "desc") {
        sortedData = [...originalData];
        setSortDirection(null);
      } else {
        sortedData.sort((a, b) => {
          if (type === "number") {
            return sortDirection === "asc"
              ? a[column] - b[column]
              : b[column] - a[column];
          } else if (type === "string") {
            return sortDirection === "asc"
              ? a[column].localeCompare(b[column])
              : b[column].localeCompare(a[column]);
          } else if (type === "date") {
            const dateA = new Date(a[column]);
            const dateB = new Date(b[column]);
            return sortDirection === "asc" ? dateA - dateB : dateB - dateA;
          }
          return 0;
        });
        setSortDirection(sortDirection === "asc" ? "desc" : "asc");
      }
    } else {
      sortedData.sort((a, b) => {
        if (type === "number") {
          return a[column] - b[column];
        } else if (type === "string") {
          return a[column].localeCompare(b[column]);
        } else if (type === "date") {
          const dateA = new Date(a[column]);
          const dateB = new Date(b[column]);
          return dateA - dateB;
        }
        return 0;
      });
      setSortColumn(column);
      setSortDirection("asc");
    }
    setData(sortedData);
  }

  if (isLoading) {
    return (
      <div>
        <Loading />
      </div>
    ); // Show a loading message while the data is being fetched
  }

  // Calculate startIndex and endIndex for slicing the data based on the currentPage and itemsPerPage
  const startIndex = (currentPage - 1) * itemsPerPage;
  // const endIndex = Math.min(startIndex + itemsPerPage, data.length);

  const pageStart = startIndex + 1;
  const pageEnd = Math.min(startIndex + itemsPerPage, data.length);
  const pageRange = `${pageStart} to ${pageEnd} of ${data.length}`;

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="funding-table-container">
            <h1 className="funding-table-h1 heavy">Funding Snapshot</h1>
            <div className="table-responsive-md">
              <table className="funding-table table">
                <thead>
                  <tr>
                    <th
                      className="funding-sortable"
                      onClick={() =>
                        handleColumnHeaderClick("location_name", "string")
                      }
                    >
                      Location{" "}
                      {sortColumn === "location_name"
                        ? sortDirection === "asc"
                          ? "▲"
                          : sortDirection === "desc"
                          ? "▼"
                          : "↕"
                        : ""}
                    </th>
                    <th
                      className="funding-sortable"
                      onClick={() =>
                        handleColumnHeaderClick("start_time", "date")
                      }
                    >
                      Period Start{" "}
                      {sortColumn === "start_time"
                        ? sortDirection === "asc"
                          ? "▲"
                          : sortDirection === "desc"
                          ? "▼"
                          : "↕"
                        : ""}
                    </th>
                    <th
                      className="funding-sortable"
                      onClick={() =>
                        handleColumnHeaderClick("end_time", "date")
                      }
                    >
                      Period End{" "}
                      {sortColumn === "end_time"
                        ? sortDirection === "asc"
                          ? "▲"
                          : sortDirection === "desc"
                          ? "▼"
                          : "↕"
                        : ""}
                    </th>
                    <th
                      className="funding-sortable"
                      onClick={() =>
                        handleColumnHeaderClick(
                          "total_successful_withdrawals",
                          "number"
                        )
                      }
                    >
                      Total Approved Withdrawals{" "}
                      {sortColumn === "total_successful_withdrawals"
                        ? sortDirection === "asc"
                          ? "▲"
                          : sortDirection === "desc"
                          ? "▼"
                          : "↕"
                        : ""}
                    </th>
                    <th
                      className="funding-sortable"
                      onClick={() =>
                        handleColumnHeaderClick(
                          "total_surcharge_amount",
                          "number"
                        )
                      }
                    >
                      Total Fee Amount{" "}
                      {sortColumn === "total_surcharge_amount"
                        ? sortDirection === "asc"
                          ? "▲"
                          : sortDirection === "desc"
                          ? "▼"
                          : "↕"
                        : ""}
                    </th>
                    <th
                      className="funding-sortable"
                      onClick={() =>
                        handleColumnHeaderClick(
                          "total_withdraw_amount",
                          "number"
                        )
                      }
                    >
                      Total Funding Eligible{" "}
                      {sortColumn === "total_withdraw_amount"
                        ? sortDirection === "asc"
                          ? "▲"
                          : sortDirection === "desc"
                          ? "▼"
                          : "↕"
                        : ""}
                    </th>
                  </tr>
                </thead>
                {data?.length !== 0 ? (
                  <tbody>
                    {data.map((row, index) => {
                      const dtStart = DateTime.fromISO(row.start_time, {
                        zone: "America/Chicago",
                        setZone: false,
                      });
                      const dtEnd = DateTime.fromISO(row.end_time, {
                        zone: "America/Chicago",
                        setZone: false,
                      });

                      return (
                        <tr key={index}>
                          <td className="td">{row.location_name}</td>
                          <td className="td">
                            {dtStart.toFormat("yyyy-MM-dd HH:mm:ss")}
                          </td>
                          <td className="td">
                            {dtEnd.toFormat("yyyy-MM-dd HH:mm:ss")}
                          </td>
                          <td className="td funding-centered-column">
                            {row.total_successful_withdrawals}
                          </td>
                          <td className="td">{`$${row.total_surcharge_amount.toFixed(
                            2
                          )}`}</td>
                          <td className="td funding-centered-column">{`$${row.total_withdraw_amount.toFixed(
                            2
                          )}`}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <></>
                )}
              </table>
              {data?.length === 0 ? (
                <>
                  <div className="no-data">
                    <p className="no-data-text">No funding data available</p>
                    <img
                      className="table-illustration"
                      src={no_data}
                      alt="no-data"
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            <TableActions
              selectedValue={selectedValue}
              handleDropdownChange={handleDropdownChange}
              currentPage={currentPage}
              totalPage={totalPage}
              itemsPerPage={itemsPerPage}
              handleChange={handleChange}
              handlePrevPage={() => {
                if (currentPage > 1) {
                  setCurrentPage(currentPage - 1);
                }
              }}
              handleNextPage={() => {
                if (currentPage < totalPage) {
                  setCurrentPage(currentPage + 1);
                }
              }}
              pageRange={pageRange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FundingTable;
