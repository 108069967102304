import React from "react";
import TablePageDetail from "../Transactions/TableActions";
import no_data from "../../imgs/no_data.svg";

function TerminalBreakdown({
  data,
  selectedValue,
  handleDropdownChange,
  currentPage,
  totalPage,
  itemsPerPage,
  handleChange,
  setCurrentPage,
  pageRange,
}) {

  return (
    <>
      <div className="table-responsive">
        <h2 className="funding-table-h1 heavy">Group Breakdown</h2>
        <table className="terminal-table table">
          <thead>
            <tr>
              <th>Group Name</th>
              <th>Total Withdrawn</th>
              <th>Total Fees</th>
              <th>Total Approved</th>
              <th>Total Declined</th>
            </tr>
          </thead>
          {data ? (
            <tbody>
                    {data.map((row, index) => (
                        <tr key={index}>
                            <td>{row.group_name}</td>
                            <td>{`$${(row.total_withdrawals).toFixed(2)}`}</td>
                            <td>{`$${(row.total_fees).toFixed(2)}`}</td>
                            <td>{row.total_approved}</td>
                            <td>{row.total_declined}</td>
                        </tr>
                    ))}
            </tbody>
          ) : (
            <></>
          )}
        </table>
        {data ? (
          <></>
        ) : (
          <div className="no-data">
            <p className="no-data-text">No data found</p>
            <img className="table-illustration" src={no_data} alt="no-data" />
          </div>
        )}
        {data ? (
          <TablePageDetail
            selectedValue={selectedValue}
            handleDropdownChange={handleDropdownChange}
            currentPage={currentPage}
            totalPage={totalPage}
            itemsPerPage={itemsPerPage}
            handleChange={handleChange}
            handlePrevPage={() => {
              if (currentPage > 1) {
                setCurrentPage(currentPage - 1);
              }
            }}
            handleNextPage={() => {
              if (currentPage < totalPage) {
                setCurrentPage(currentPage + 1);
              }
            }}
            pageRange={pageRange}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default TerminalBreakdown;
