import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "./loginbutton.css";

function LoginButton({ isInProfile }) {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const containerClass = isInProfile
    ? "login-container-small"
    : "login-container";

  if (isAuthenticated) {
    return <p className={containerClass}>You are logged in!</p>;
  }

  return (
    <button className="logout-btn" onClick={() => loginWithRedirect()}>
      Log In
    </button>
  );
}

export default LoginButton;
