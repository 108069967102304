import React from "react";
import no_data from "../../imgs/no_data.svg";

function TransactionTable({
  data,
  sortColumn,
  sortDirection,
  handleColumnHeaderClick,
  miniLoader,
}) {
  return (
    <div className="table-responsive">
      <table className="transaction-table table">
        <thead>
          <tr>
            <th
              className="sortable"
              onClick={() => handleColumnHeaderClick("date_time", "date")}
            >
              Date | Time
              {sortColumn === "date_time"
                ? sortDirection === "asc"
                  ? "▲"
                  : sortDirection === "desc"
                  ? "▼"
                  : "↕"
                : ""}
            </th>
            <th
              className="sortable"
              onClick={() => handleColumnHeaderClick("location", "string")}
            >
              Location{" "}
              {sortColumn === "location"
                ? sortDirection === "asc"
                  ? "▲"
                  : sortDirection === "desc"
                  ? "▼"
                  : "↕"
                : ""}
            </th>

            {/* Serial Number */}
            <th
              className="sortable"
              onClick={() => handleColumnHeaderClick("serial_num", "string")}
            >
              Serial Number
              {sortColumn === "serial_num"
                ? sortDirection === "asc"
                  ? "▲"
                  : sortDirection === "desc"
                  ? "▼"
                  : "↕"
                : ""}
            </th>

            {/* Terminal Seq */}
            <th
              className="sortable"
              onClick={() => handleColumnHeaderClick("terminal_seq", "number")}
            >
              Terminal Seq{" "}
              {sortColumn === "terminal_seq"
                ? sortDirection === "asc"
                  ? "▲"
                  : sortDirection === "desc"
                  ? "▼"
                  : "↕"
                : ""}
            </th>

            {/* Activity */}
            <th
              className="sortable"
              onClick={() => handleColumnHeaderClick("activity", "number")}
            >
              Activity
              {sortColumn === "activity"
                ? sortDirection === "asc"
                  ? "▲"
                  : sortDirection === "desc"
                  ? "▼"
                  : "↕"
                : ""}
            </th>

            {/* Account Number */}
            <th
              className="sortable"
              onClick={() => handleColumnHeaderClick("pan_number", "string")}
            >
              Account Number
              {sortColumn === "pan_number"
                ? sortDirection === "asc"
                  ? "▲"
                  : sortDirection === "desc"
                  ? "▼"
                  : "↕"
                : ""}
            </th>

            {/* Amount */}
            <th
              className="sortable"
              onClick={() =>
                handleColumnHeaderClick("withdrawal_amount", "number")
              }
            >
              Amount
              {sortColumn === "withdrawal_amount"
                ? sortDirection === "asc"
                  ? "▲"
                  : sortDirection === "desc"
                  ? "▼"
                  : "↕"
                : ""}
            </th>

            {/* Fees */}
            <th
              className="sortable"
              onClick={() =>
                handleColumnHeaderClick("surcharged_amount", "number")
              }
            >
              Fee
              {sortColumn === "surcharged_amount"
                ? sortDirection === "asc"
                  ? "▲"
                  : sortDirection === "desc"
                  ? "▼"
                  : "↕"
                : ""}
            </th>

            {/* Status */}
            <th
              className="sortable text-center"
              style={{ paddingRight: "15px" }}
              onClick={() => handleColumnHeaderClick("rc", "number")}
            >
              Status
              {sortColumn === "rc"
                ? sortDirection === "asc"
                  ? "▲"
                  : sortDirection === "desc"
                  ? "▼"
                  : "↕"
                : ""}
            </th>
          </tr>
        </thead>
        {data?.length !== 0 ? (
          <>
            <tbody>
              {data.map((row, index) => (
                <tr
                  key={index}
                  className={row.rc === 0 ? "approved" : "declined"}
                >
                  <td>
                    {new Intl.DateTimeFormat("en-US", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                      hour12: false,
                    }).format(new Date(row.date_time))}
                  </td>
                  <td>{row.location}</td>
                  <td>{row.serial_num}</td>
                  <td className="text-center">{row.terminal_seq}</td>
                  <td>{row.activity === 1 ? "Withdrawal" : "Balance Inq"}</td>
                  <td>{row.pan_number}</td>
                  <td>{`$${new Intl.NumberFormat().format(
                    row.withdrawal_amount
                  )}.00`}</td>
                  <td>{`$${row.surcharged_amount.toFixed(2)}`}</td>
                  <td>
                    <div
                      className={`status-label ${
                        row.rc === 0
                          ? "status-label-approved"
                          : "status-label-declined"
                      }`}
                      title={row.code_description}
                    >
                      {row.rc === 0 ? "Approved" : `Declined `}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </>
        ) : (
          <></>
        )}
      </table>
      {data?.length === 0 ? (
        <>
          <div className="no-data">
            <p className="no-data-text">No data found</p>
            <img className="table-illustration" src={no_data} alt="no-data" />
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default TransactionTable;
