import React, { useState, useEffect, useContext } from "react";
import RebateReport from "../components/rebatereport";
import "./reportspage.css";
import { useAuth0 } from "@auth0/auth0-react";
import PartnerReport from "../components/partnerreport";
import { SelectedOptionContext } from "../components/selectedoptioncontext";
import Loading from "../components/loading";
import GroupDayTable from "../components/groupdaytable";
import GroupMonthTable from "../components/groupmonthtable";
import ReportsTab from "../components/Reports/ReportsTab";

function ReportsPage() {
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
  const { selectedOption } = useContext(SelectedOptionContext);
  const [groupedByMonth, setGroupedByMonth] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const reportsURL = process.env.REACT_APP_BACKEND_REPORTS_URL;
  const [userRole, setUserRole] = useState(null);
  const [activeState, setActiveState] = useState("daily");

  // Grabs Role When it is availible
  useEffect(() => {
    if (isAuthenticated && user) {
      const fetchedRole = user["https://my-app.example.com/roles"][0];
      setUserRole(fetchedRole);
    }
  }, [user, isAuthenticated]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(reportsURL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        //console.log(data);  // Add this line to debug

        // Group data by month
        const groups = data.reduce((groups, item) => {
          const month = item.month_end_date;
          if (!groups[month]) {
            groups[month] = [];
          }
          groups[month].push(item);
          return groups;
        }, {});

        setGroupedByMonth(groups);
        setIsLoading(false); // Ends loading JSX after error occurs so people don't wait forever.
      } catch (err) {
        console.error(err);
        setIsLoading(false); // Ends loading JSX after error occurs so people don't wait forever.
      }
    };
    fetchData(); // Must keep comment on line 25, disables warning given when using env variable in useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <div>
        <Loading />
      </div>
    ); // Show a loading message while the data is being fetched
  }

  // WHAT We'll do is depending on the Dropdown for the user logged in & Dropdown is determined by the roles user logged in has, WE WILL GIVE THE LAYOUT OF THE HOMEPAGE. Same for App.JS?
  if (isAuthenticated && userRole === "Dannah Org") {
    return (
      <div>
        <h1 className="center-text">Reports</h1>
        <div className="button-container">
          {Object.keys(groupedByMonth).map((month) => (
            <RebateReport month={month} dataForMonth={groupedByMonth[month]} />
          ))}
        </div>
        <p> </p>
        <div className="button-container">
          {Object.keys(groupedByMonth).map((month) => (
            <PartnerReport month={month} dataForMonth={groupedByMonth[month]} />
          ))}
        </div>
      </div>
    );
  } else if (isAuthenticated && userRole === "BlueSage Org") {
    return (
      <div>
        <h1>No Reports Yet For {selectedOption}</h1>
      </div>
    );
  } else if (isAuthenticated && userRole === "SmartATM Org") {
    return (
      // <div>
      //   <h1> Reports </h1>
      //   <GroupDayTable/>
      //   <GroupMonthTable/>
      // </div>
      <>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="funding-table-h1 heavy">Reports</h1>
              </div>
              <div className="col-lg-12">
                <ReportsTab
                  activeState={activeState}
                  setActiveState={setActiveState}
                />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {activeState === "daily" ? (
                  <GroupDayTable />
                ) : (
                  <GroupMonthTable />
                )}
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }

  return (
    <div>
      <h1>Please Log in.</h1>
    </div>
  );
}

export default ReportsPage;
