import React, { useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { SelectedOptionContext } from "../components/selectedoptioncontext";
import SmartDashValues from "../components/smartdashvalues";
import FundingTable from "../components/fundingtable";

function HomePage() {
  //On this page we could use, isAuthenticated, from useAuth0 to see if user is authenticated, if not return a login message for the home screen
  const { isAuthenticated, user } = useAuth0();
  const { selectedOption } = useContext(SelectedOptionContext);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    if (isAuthenticated && user) {
      const fetchedRole = user["https://my-app.example.com/roles"][0];
      setUserRole(fetchedRole);
    }
  }, [user, isAuthenticated]);

  // WHAT We'll do is depending on the Dropdown for the user logged in & Dropdown is determined by the roles user logged in has, WE WILL GIVE THE LAYOUT OF THE HOMEPAGE. Same for App.JS?
  if (isAuthenticated && userRole === "SmartATM Org") {
    return (
      <div>
        <SmartDashValues />
        <FundingTable />
      </div>
    );
  } else if (isAuthenticated && userRole === "BlueSage Org") {
    return (
      <div>
        <h1>Welcome</h1>
        {selectedOption}
      </div>
    );
  } else if (isAuthenticated && userRole === "Dannah Org") {
    return (
      <div>
        <h1>Welcome</h1>
        {selectedOption}
      </div>
    );
  }

  return (
    <div>
      <h1>Home Page</h1>
      <h2>Go to Login page First, Login</h2>
      <h2>Then View Production & Reports Page</h2>
    </div>
  );
}

export default HomePage;
