import React from "react";
import arrowUp from "../../imgs/ion_arrow-up.svg";
import arrowDown from "../../imgs/ion_arrow-down.svg";

function DashboardData({ dataTitle, information, change }) {
  let amount = "";
  let decimal = "";

  if (typeof information === "string" && information.includes(".")) {
    const parts = information.split(".");
    amount = parts[0];
    decimal = parts[1];
  } else if (typeof information === "string") {
    amount = information;
  }

  return (
    <div className="col-lg-3">
      <div className="dashboard-item-column">
        <div className="align-heading">
          <p className="day-heading normal">{dataTitle}</p>
          <div className="align-arrow">
            <div
              className={`arrow-circle ${change ? "lightGreen" : "lightRed"}`}
            >
              {change ? (
                <img className="arrow-img" src={arrowUp} alt="arrow-icon" />
              ) : (
                <img className="arrow-img" src={arrowDown} alt="arrow-icon" />
              )}
            </div>
            <h6 className="percentage green">{/* {percentage} */}</h6>
          </div>
        </div>
        <p className="number heavy">
          <span>{amount}</span>
          {decimal && <span className="decimal">.{decimal}</span>}{" "}
        </p>
      </div>
    </div>
  );
}

export default DashboardData;
