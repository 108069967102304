import React from 'react';
import { jsPDF } from "jspdf";
import logo from '../imgs/tclogo.png';
import './rebatereport.css';

function RebateReport({ month, dataForMonth }) {
  const downloadReport = () => {
        // Create a new jsPDF instance
        const doc = new jsPDF();

        // Add logo
        // Note: You need to have the logo available as a Base64 encoded string
        doc.addImage(logo, 'png', 40, 20, 116, 30);

        // Add table header
        doc.setFontSize(32);
        doc.text('Location', 15, 77);
        doc.text('Date', 82, 77);
        doc.text('Sales Volume', 125, 77);
        doc.line(10, 80, 199, 80, 'S'); //Add Line(x1,y1,x2,y2, 'style')Graphing!

        // Add table body
        let totalSalesVolume = 0;
        let Surcharge = 0;
        dataForMonth.forEach((sale, index) => {
        const y = 90 + index * 10;
        doc.setFontSize(18);
        doc.text(sale.merchant_name, 10, y);
        doc.text(sale.month_end_date, 80, y);
        doc.text(`$`, 152, y);
        doc.text(`${new Intl.NumberFormat().format(sale.net_sales_vol)}`, 195, y, 'right');
        totalSalesVolume += parseFloat(sale.net_sales_vol);
        Surcharge = (totalSalesVolume * .035).toFixed(2);
        });

        let rebate = ((totalSalesVolume * .005) * .25);
        let rebatePercent = (rebate / Surcharge) * 100;
        // Add totals
        doc.setFontSize(20);
        doc.text(`Total Sales Volume: `, 80, 123);
        doc.text(`$`, 152, 123);
        doc.text(`${new Intl.NumberFormat().format(totalSalesVolume)}`, 195, 123, 'right');
        doc.line(75, 125, 199, 125, 'S'); //Add Line(x1,y1,x2,y2, 'style')Graphing!
        doc.setFontSize(18);
        doc.text(`Surcharge:`, 15, 147);
        doc.text(`3.5%`, 80, 147);
        doc.text(`$`, 152, 147);
        doc.text(`${new Intl.NumberFormat().format(Surcharge)}`, 195, 147, 'right');
        doc.line(10, 149, 199, 149, 'S'); //Add Line(x1,y1,x2,y2, 'style')Graphing!
        doc.text(`Savings:`, 15, 160);
        doc.text(`$`, 152, 160);
        doc.text(`${new Intl.NumberFormat().format(Surcharge)}`, 195, 160, 'right');
        doc.line(10, 162, 199, 162, 'S'); //Add Line(x1,y1,x2,y2, 'style')Graphing!
        doc.text(`Rebate:`, 15, 173);
        doc.text(`${rebatePercent.toPrecision(3)}%`, 80, 173);
        doc.text(`$`, 152, 173);
        doc.text(`${rebate.toFixed(2)}`, 195, 173, 'right');
        doc.line(10, 175, 199, 175, 'S'); //Add Line(x1,y1,x2,y2, 'style')Graphing!

        doc.setFontSize(26);
        doc.text(`Rebate:`, 80, 205);
        doc.text(`$`, 152, 205);
        doc.text(`${rebate.toFixed(2)}`, 195, 205, 'right');
        doc.line(75, 208, 199, 208, 'S'); //Add Line(x1,y1,x2,y2, 'style')Graphing!
        doc.setFontSize(12);
        doc.text(`* Rebate = ${rebatePercent.toPrecision(3)}% of Savings.`, 15, 280);

        // Save the PDF
        doc.save(`Rebate_report_${month}.pdf`);
  }

  return (
    <button className='report-button' onClick={downloadReport}>
      Download {month}  Rebate Report
    </button>
  );
}

export default RebateReport;