import React, { useState } from "react";
import up from "../../imgs/up.svg";

function TransactionFilters({
  serialNum,
  statusFilter,
  handleStatusChange,
  handleSerialNumChange,
  isDropdownOpen,
  setIsDropdownOpen,
  locations,
  locationFilter,
  handleLocationChange,
  panNumber,
  handlePanNumberChange,
  startDate,
  endDate,
  handleStartDateChange,
  handleEndDateChange,
  handleFormSubmit,
}) {
  const [isTransactionFormOpen, setTransactionFormOpen] = useState(false);

  const handleButtonToggle = () => {
    setTransactionFormOpen(!isTransactionFormOpen);
  };

  return (
    <>
      <div className="transaction-container">
        <h2 className="transaction-heading heavy">Transactions</h2>
        <div>
          <button
            className={`filter-select ${
              isTransactionFormOpen ? "rotated-bg" : ""
            }`}
            onClick={handleButtonToggle}
          >
            {/* {isTransactionFormOpen ? "Filter" : "Filter"} */}
            Filter
          </button>
        </div>
      </div>
      <div
        className={`transaction-form ${isTransactionFormOpen ? "open" : ""}`}
      >
        {isTransactionFormOpen && (
          <div className="transaction-detail">
            <form className="input-container" onSubmit={handleFormSubmit}>
              <label className="input-label normal">
                Serial Number
                <input
                  className="input-transaction"
                  type="text"
                  value={serialNum}
                  onChange={handleSerialNumChange}
                  placeholder="Enter Serial Number"
                />
              </label>
              <div className="input-label normal">
                Location
                <div className="input-transaction">
                  <button
                    className="dropdown-label"
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  >
                    <div className="dropdown-label-content">
                      Select Location
                      <span className="dropdown-arrow">
                        <img
                          src={up}
                          className={`rotate-icon ${
                            isDropdownOpen ? "rotated" : ""
                          }`}
                          alt="up-icon"
                        />
                        {/* {isDropdownOpen ? (
                        ) : (
                          <img src={dropdownarrow} alt="dropdown-icon" />
                        )} */}
                      </span>
                    </div>
                  </button>
                  {isDropdownOpen && (
                    <div className="dropdown">
                      {locations.map((location) => (
                        <label key={location}>
                          <input
                            type="checkbox"
                            checked={locationFilter[location] || false}
                            onChange={() => {
                              handleLocationChange(location);
                            }}
                            placeholder="Select Location"
                          />
                          {location}
                        </label>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <label className="input-label normal">
                Account Number
                <input
                  className="input-transaction"
                  type="text"
                  value={panNumber}
                  onChange={handlePanNumberChange}
                  placeholder="Enter Account Number"
                />
              </label>
              <label className="input-label normal">
                Start Date
                <input
                  className="input-transaction"
                  type="date"
                  value={startDate.toISOString().substring(0, 10)}
                  onChange={handleStartDateChange}
                  placeholder="Select Date"
                />
              </label>
              <label className="input-label normal">
                End Date
                <input
                  className="input-transaction"
                  type="date"
                  value={endDate.toISOString().substring(0, 10)}
                  onChange={handleEndDateChange}
                  placeholder="Select Date"
                />
              </label>
              <label className="input-label normal">
                Status
                <select
                  className="input-transaction"
                  value={statusFilter}
                  onChange={handleStatusChange}
                >
                  <option value="">All</option>
                  <option value="Approved">Approved</option>
                  <option value="Denied">Denied</option>
                </select>
              </label>
              <input className="submit-button" type="submit" value="Submit" />
            </form>
          </div>
        )}
      </div>
    </>
  );
}

export default TransactionFilters;
