import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "./loginbutton.css";

function LogoutButton({ isInProfile }) {
  const { logout } = useAuth0();
  
  return (
    // <div className={containerClass}>
    // </div>
    <button className="logout-btn" onClick={() => logout()}>
      Log Out
    </button>
  );
}

export default LogoutButton;
