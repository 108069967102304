import React from 'react';

function ConvertToCSV(objArray) {
    let str = '';

    // Add headers
    str += Object.keys(objArray[0]).join(',') + '\r\n';

    // Add rows
    for (let i = 0; i < objArray.length; i++) {
        let line = '';
        for (const index in objArray[i]) {
            if (line !== '') line += ',';

            // Check if the field is a date field
            if (index === 'date_time') {
                const localDate = new Date(objArray[i][index]).toLocaleString();
                line += localDate;
            } else {
                line += objArray[i][index];
            }
        }

        str += line + '\r\n';
    }

    return str;
}

const DownloadCSV = ({ data }) => {
    const handleDownload = () => {
        if (!data || data.length === 0) {
            console.error('No data available for download');
            return;
        }

        const csvStr = ConvertToCSV(data);
        const blob = new Blob([csvStr], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'transactions.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <button onClick={handleDownload}>Download Transactions</button>
    );
};

export default DownloadCSV;