import React from "react";

function ReportsTab({ activeState, setActiveState }) {
  return (
    <div>
      <button
        className={`report-tab ${
          activeState === "daily" ? "active-report" : ""
        }`}
        // onClick={() => {
        //   setActiveState("daily");
        // }}
        onClick={() => {
          setActiveState("daily");
        }}
      >
        Daily
      </button>
      <button
        className={`report-tab ${
          activeState === "monthly" ? "active-report" : ""
        }`}
        onClick={() => {
          setActiveState("monthly");
        }}
      >
        Monthly
      </button>
    </div>
  );
}

export default ReportsTab;
