import React from "react";

function Heading({ title, image }) {
  return (
    <div className="atm-align">
      <img className="atm-logo" src={image} alt="atm_logo" />

      <h2 className="heavy">{title}</h2>
    </div>
  );
}

export default Heading;
